<template>
<div>
    <section id="recent-blog-posts" class="recent-blog-posts" v-if="!(language.indexOf('es') === -1)" >
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Blog</h2>
          <p>Post recientes de nuestro blog</p>
        </header>
        <div class="row">
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-1.webp" class="img-fluid" alt="" style="width:280px"></div>
              <span class="post-date">Tue, September 15</span>
              <h3 class="post-title">Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto" style="display:block;"><span>Leer Nota</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-2.webp" class="img-fluid" alt="" style="width:280px"></div>
              <span class="post-date">Fri, August 28</span>
              <h3 class="post-title">Et repellendus molestiae qui est sed omnis voluptates magnam</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto" style="display:block;"><span>Leer Nota</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-3.webp" class="img-fluid" alt="" style="width:280px"></div>
              <span class="post-date">Mon, July 11</span>
              <h3 class="post-title">Quia assumenda est et veritatis aut quae</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto" style="display:block;"><span>Leer Nota</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>
        <section id="recent-blog-posts" class="recent-blog-posts" v-if="(language.indexOf('es') === -1)" >
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Blog</h2>
          <p>Recent Posts</p>
        </header>
        <div class="row">
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-1.webp" class="img-fluid" alt="" style="width:280px"></div>
              <span class="post-date">Tue, September 15</span>
              <h3 class="post-title">Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto" style="display:block;"><span>Leer Nota</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-2.webp" class="img-fluid" alt="" style="width:280px"></div>
              <span class="post-date">Fri, August 28</span>
              <h3 class="post-title">Et repellendus molestiae qui est sed omnis voluptates magnam</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto" style="display:block;"><span>Leer Nota</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img"><img src="assets/img/blog/blog-3.webp" class="img-fluid" alt="" style="width:280px"></div>
              <span class="post-date">Mon, July 11</span>
              <h3 class="post-title">Quia assumenda est et veritatis aut quae</h3>
              <a href="blog-singe.html" class="readmore stretched-link mt-auto" style="display:block;"><span>Leer Nota</span><i class="bi bi-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>
</div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String,
    language: String
  },
  created () {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
