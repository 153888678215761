<template>
<div>
     <section id="testimonials" class="testimonials" v-if="!(language.indexOf('es') === -1)" >
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Nuestros Clientes</h2>
          <p>Que dicen de nosotros</p>
        </header>
          <TestimoniosSlider v-if="!mobileView" />
      </div>
    </section>
         <section id="testimonials" class="testimonials" v-if="(language.indexOf('es') === -1)" >
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Our Customers</h2>
          <p>----</p>
        </header>
          <TestimoniosSlider v-if="!mobileView" />
      </div>
    </section>
</div>
</template>

<script>
// import TestimoniosSlider from './TestimoniosSlider.vue'

export default {
  name: 'Testimonios',
  props: {
    msg: String,
    language: String
  },
  components: { TestimoniosSlider: () => import(/* webpackPrefetch: true */ './TestimoniosSlider.vue') },
  data () {
    return {
      mobileView: true
    }
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    }
  },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#testimonial-item{
  width: 40%;
}

</style>
