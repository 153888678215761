<template>
<div>
     <section id="about" class="about" v-if="!(language.indexOf('es') === -1)" >
      <div class="container" data-aos="fade-up">
        <div class="row gx-0">
          <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div class="content">
              <h3>Quienes somos?</h3>
              <h2>Con más de 15 años de historia hemos alcanzado estandares en la provisión de servicios y gestión de procesos de Negocios.
              </h2>
              <p>Nuestra meta es acompañarte con los objetivos de la organización utilizando elementos tecnológicos a la medida.
              </p>
              <div class="text-center text-lg-start center" style="width:100%" >
                <a  v-if="mobileView" rel="noopener noreferrer" href="https://booking.setmore.com/scheduleappointment/7c0dd884-0b7c-4d20-97e4-eae40c58a6e9/services/fba41fc6-a981-4b93-a0ed-4ebdf2e87dea" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Programa una Reunion</span>
                </a>
                <MeetDialog v-if="!mobileView" :language = "'es'"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            <img src="assets/img/about.webp" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </section>
         <section id="about" class="about" v-if="(language.indexOf('es') === -1)">
      <div class="container" data-aos="fade-up">
        <div class="row gx-0">
          <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div class="content">
              <h3>Who we are?</h3>
              <h2> Con mas de 15 años de historia hemos alcanzado estándares en la provisión de servicios y Gestion de procesos del Negocio.
              </h2>
              <p>Nuestra meta es acompañarte con los objetivos de la organización utilizando elementos tecnológicos a la medida.
              </p>
              <div class="text-center text-lg-start center" style="width:100%" >
                <a  v-if="mobileView" rel="noopener noreferrer" href="https://booking.setmore.com/scheduleappointment/7c0dd884-0b7c-4d20-97e4-eae40c58a6e9/services/fba41fc6-a981-4b93-a0ed-4ebdf2e87dea" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Schedule a Meeting</span>
                </a>
                <MeetDialog v-if="!mobileView" :language = "'en'"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            <img src="assets/img/about.webp" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </section>
</div>
</template>

<script>
import MeetDialog from '../../components/MeetDialog.vue'

export default {
  name: 'About',
  data: () => {
    return {
      mobileView: true
    }
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    }
  },
  created () {
    console.log('Esto llego a About')
    console.log(this.language)
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  components: {
    MeetDialog
  },
  props: {
    msg: String,
    language: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

.img-fluid {
    max-width: 100%;
    height: 100%;
    width: 100%;
}

</style>
