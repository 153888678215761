<template>
   <div>
      <Soluciones  :language = "lenguajeactual"/>
      <About  :language = "lenguajeactual"/>
      <Alianzas  :language = "lenguajeactual"/>
      <Features  :language = "lenguajeactual"/>
      <RecentPost  v-if="1==2"  :language = "lenguajeactual"/>
      <Testimonios v-if="1==2" :language = "lenguajeactual" />
      <NavigationLogin v-if="admin"/>
    </div>
</template>

<script>
import Soluciones from './Soluciones.vue'
import About from './About.vue'
import Alianzas from './Alianzas.vue'
import Features from './Features.vue'
import RecentPost from './RecentPost.vue'
import Testimonios from './Testimonios.vue'
import NavigationLogin from '../../components/Navigation.vue'

export default {
  components: {
    Soluciones, About, Alianzas, Features, RecentPost, Testimonios, NavigationLogin
  },
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      mobileView: true,
      lenguajeactual: this.getlanguage()
    }
  },
  methods: {
    getlanguage () {
      console.log(window.location.hash)
      if (!(window.location.hash.indexOf('es') === -1)) {
        console.log('Dice Es AsyncHome')
        return 'es'
      }
      if (!(window.location.hash.indexOf('en') === -1)) {
        console.log('Dice En AsyncHome')
        return 'en'
      } else {
        console.log('Lenguaje Automático')
        return navigator.language
      }
    },
    handleView () {
      this.mobileView = window.innerWidth <= 990
    }
  },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    admin () {
      return this.$store.state.profileAdmin
    }
  }
}
</script>

<style>

</style>
