<template>
<div>
      <section id="soluciones" class="values" v-if="!(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Soluciones a Medida</h2>
            <p>Soluciones integrales</p>
          </header>
          <div class="row">
            <div class="col-lg-3">
              <router-link :to="{ name: 'Disaster Recovery', params: { language }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/dr.webp" class="img-fluid" alt="">
                <h3>Disaster Recovery Solutions</h3>
                <p style="color:#2c3e50;">Protege los servicios críticos de tu organización y previene la perdida de datos en plataformas de TI.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-3 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Cybersecurity', params: { language }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/sec.webp" class="img-fluid" alt="">
                <h3>Cybersecurity</h3>
                <p style="color:#2c3e50;">Soluciones para la protección de datos bajo los estandares de seguridad más reconocidos</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-3 mt-4 mt-lg-0">
              <router-link :to="{ name: 'IT Services', params: { language }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/it.webp" class="img-fluid" alt="">
                <h3>IT Services</h3>
                <p style="color:#2c3e50;">Consultoría, planificación y operación en el área de Tecnología de la Información.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-3 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Hardware Software', params: { language }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/hard.webp" class="img-fluid" alt="">
                <h3>Hardware & Software</h3>
                <p style="color:#2c3e50;">Soluciones integrales a la medida de tu organización. Renová tu Infraestructura productiva con las mejores marcas del mercado.</p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
            <section id="soluciones" class="values" v-if="(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2></h2>
            <p>Our Solutions</p>
          </header>
          <div class="row">
            <div class="col-lg-3">
              <router-link :to="{ name: 'Disaster RecoveryEn', params: { language }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/dr.webp" class="img-fluid" alt="">
                <h3>Disaster Recovery Solutions</h3>
                <p style="color:#2c3e50;">Protege los servicios críticos de tu organización y prevení perdida de datos en plataformas de TI.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-3 mt-4 mt-lg-0">
              <router-link :to="{ name: 'CybersecurityEn', params: { language }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/sec.webp" class="img-fluid" alt="">
                <h3>Cybersecurity</h3>
                <p style="color:#2c3e50;"> Soluciones para la protección de datos. Alieneate a los estandares de seguridad más reconocidos </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-3 mt-4 mt-lg-0">
              <router-link :to="{ name: 'IT ServicesEn', params: { language }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/it.webp" class="img-fluid" alt="">
                <h3>IT Services</h3>
                <p style="color:#2c3e50;"> Consultoría, planificación y operación en el área de Tecnología de la Información. </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-3 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Hardware SoftwareEn', params: { language }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/hard.webp" class="img-fluid" alt="">
                <h3>Hardware & Software</h3>
                <p style="color:#2c3e50;"> Soluciones integrales a medida para tu organización. Renová tu Infraestructura productiva con las mejores marcas del mercado. </p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String,
    language: String
  },
  created () {
    console.log('Esto llego a Soluciones')
    console.log(this.language)
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.values .box {
}

.img-fluid {
    max-width: 110%;
    height: 200px;
    width: 250px;
}

.values .box img {
    padding: 20px;
}

</style>
