<template>
<div>
      <section id="partners" class="clients" v-if="!(language.indexOf('es') === -1)" >
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Alianzas</h2>
            <p>Nuestros socios tecnológicos</p>
          </header>

        <div>
                <AlianzasSlider v-if="!mobileView" />
                <div v-if="mobileView"><img src="assets/img/clients/alianzas.webp" class="img-fluid" alt=""></div>
        </div>
        </div>
      </section>
            <section id="partners" class="clients" v-if="(language.indexOf('es') === -1)" >
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Alliances</h2>
            <p>Our Tech Partners</p>
          </header>

        <div>
                <AlianzasSlider v-if="!mobileView" />
                <div v-if="mobileView"><img src="assets/img/clients/alianzas.webp" class="img-fluid" alt=""></div>
        </div>
        </div>
      </section>
      </div>
</template>

<script>
// import AlianzasSlider from './AlianzasSlider.vue'

export default {
  name: 'Alianzas',
  props: {
    msg: String,
    language: String
  },
  components: { AlianzasSlider: () => import(/* webpackPrefetch: true */ './AlianzasSlider.vue') },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.slick-slide img[data-v-e4caeaf8] {

width: 199px !important;;
height: 100% !important;

}

</style>
